import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ScanFile } from 'src/app/updates/cert-tools/page/cert-tools-items';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpFileAccessService {

  // API endpoint
  private fileAccessUrl = environment.endpoints.fileAccess;
  
  constructor(private httpClient: HttpClient) {}

    /**
   * GET /profile-picture
   */
     async getProfilePicture(): Promise<Blob> {
      const url = this.fileAccessUrl + 'profile-picture';
      const $picture = this.httpClient.get(url, {
        reportProgress: true,
        responseType: 'blob',
      });
      return await firstValueFrom($picture);
    }
  
    /**
     *  POST /profile-picture
     */
    async updateProfilePicture(file: File): Promise<Object> {
      const url = this.fileAccessUrl + 'profile-picture';
      const formData = new FormData();
      formData.append('file', file);
      const $update = this.httpClient.post(url, formData, {
        reportProgress: true,
        responseType: 'json',
      });
      return await firstValueFrom($update);
    }
  
    /**
     * DELETE /profile-picture
     */
    async deleteProfilePicture(): Promise<Object> {
      const url = this.fileAccessUrl + 'profile-picture';
      const $deletion = this.httpClient.delete(url);
      return await firstValueFrom($deletion);
    }
   

}
