import { Component, OnInit, ViewChild } from '@angular/core';
import { SubPages } from 'src/app/shared/model/shared-items';
import { Utilities } from 'src/app/shared/utilities';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { ACTIONS, PAGES, TYPES } from 'src/app/shared/constants';
import { ActivityAnalyticsComponent } from './activity-analytics/activity-analytics.component';
import { ActionsAnalyticsComponent } from './actions-analytics/actions-analytics.component';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  @ViewChild(ActivityAnalyticsComponent) activityAnalyticsView!: ActivityAnalyticsComponent;
  @ViewChild(ActionsAnalyticsComponent) actionsAnalyticsView!: ActionsAnalyticsComponent;

  filterParameters: FilterParameters = defaultFilterParameters;

  tabViews = [this.activityAnalyticsView, this.actionsAnalyticsView];
  tabs = {actionsAnalytics: SubPages.actionsAnalytics, activityAnalytics: SubPages.activityAnalytics};
  activeTab: SubPages = this.tabs.activityAnalytics;

  constructor(private readonly userActivityService: UserActivityService) {}

  ngOnInit(): void {
    this.userActivityService.logActivity(TYPES.user, PAGES.analytics, ACTIONS.visit);
    this.tabViews.forEach( view => {
      if (view?.componentLoaded === true) {
        this.doFilter();
      }
    });
  }

  switchTab(tab: SubPages) {
    this.activeTab = tab;
    this.doFilter(this.filterParameters);
  }

  public doFilter(params?: FilterParameters) {
    if (params) {
      this.filterParameters = params;
    }
    if (this.activeTab === SubPages.actionsAnalytics) {
      this.actionsAnalyticsView?.filter(this.filterParameters);
    } else if (this.activeTab === SubPages.activityAnalytics) {
      this.activityAnalyticsView?.filter(this.filterParameters);
    }
  }
}

export class WidgetsOptions {
  options: Highcharts.Options;
  className: string;

  constructor(options: Highcharts.Options, className: string) {
    this.options = options;
    this.className = className;
  }
}

export type FilterParameters = {
  createdFrom: string;
  createdTo: string;
  selectedRadioRange: string;
  selectedRadioDate: string;
};

export const defaultFilterParameters: FilterParameters = {
  createdFrom: Utilities.get1MonthAgo(),
  createdTo: Utilities.getToday(),
  selectedRadioRange: 'all',
  selectedRadioDate: 'lastMonth'
};
