<div class="question">
    <div class="rating">
        <form [formGroup]="surveyForm">
          <div [formArrayName]="question.title">
            <fieldset class="star-rating" [formGroupName]="0">
              <legend class="visually-hidden">Results relevance</legend>
          
              <input type="radio" id="terrible_1" value="1" class="visually-hidden" formControlName="answer">
              <label for="terrible_1" title="Terrible"><span class="visually-hidden">Terrible</span></label>

              <input type="radio" id="terrible_2" value="2" class="visually-hidden" formControlName="answer">
              <label for="terrible_2" title="Terrible"><span class="visually-hidden">Terrible</span></label>
          
              <input type="radio" id="bad_1" value="3" class="visually-hidden" formControlName="answer">
              <label for="bad_1" title="Bad"><span class="visually-hidden">Bad</span></label>

              <input type="radio" id="bad_2" value="4" class="visually-hidden" formControlName="answer">
              <label for="bad_2" title="Bad"><span class="visually-hidden">Bad</span></label>
          
              <input type="radio" id="mixed_1" value="5" class="visually-hidden" formControlName="answer">
              <label for="mixed_1" title="Mixed"><span class="visually-hidden">Mixed</span></label>

              <input type="radio" id="mixed_2" value="6" class="visually-hidden" formControlName="answer">
              <label for="mixed_2" title="Mixed"><span class="visually-hidden">Mixed</span></label>
          
              <input type="radio" id="good_1" value="7" class="visually-hidden" formControlName="answer">
              <label for="good_1" title="Good"><span class="visually-hidden">Good</span></label>

              <input type="radio" id="good_2" value="8" class="visually-hidden" formControlName="answer">
              <label for="good_2" title="Good"><span class="visually-hidden">Good</span></label>
          
              <input type="radio" id="excellent_1" value="9" class="visually-hidden" formControlName="answer">
              <label for="excellent_1" title="Excellent"><span class="visually-hidden">Excellent</span></label>

              <input type="radio" id="excellent_2" value="10" class="visually-hidden" formControlName="answer">
              <label for="excellent_2" title="Excellent"><span class="visually-hidden">Excellent</span></label>

            </fieldset>
            <div [formGroupName]="1">
              <textarea class="feedback-text" name="feedback" id="feedback" placeholder="{{'pages.feedbacks.surveyComponents.ratingComponent.ratingFeedback' | translate}}" formControlName="feedback"></textarea>
            </div>
          </div>  
        </form>
    </div>
</div>