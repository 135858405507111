import { Component } from '@angular/core';
import { AbstractQuestionComponent } from '../abstract-question/abstract-question.component';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingQuestionComponent extends AbstractQuestionComponent {
}
