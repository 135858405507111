import { AfterContentInit, Component, computed, OnChanges, signal, Signal, SimpleChanges, WritableSignal } from '@angular/core';
import { FileViewComponent } from '../file-view/file-view.component';
import { IconsModule } from '../../modules/icons/icons.module';
import { DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FileStructureArray } from '../../model/files';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-file-view-icons',
  standalone: true,
  imports: [IconsModule, TranslateModule, DatePipe, FormsModule],
  templateUrl: './file-view-icons.component.html',
  styleUrl: './file-view-icons.component.scss'
})
export class FileViewIconsComponent extends FileViewComponent implements AfterContentInit, OnChanges{

  private currentFileStructureArray: WritableSignal<FileStructureArray> = signal([]);
  private parentFileStructureHistory: WritableSignal<FileStructureArray[]> = signal([]);
  public breadCrumb: string[] = [];

  public isDrillDown: Signal<boolean> = computed(() => {
    return JSON.stringify(this.currentFileStructureArray) !== JSON.stringify(this.viewedFileStructureArray)
  })

  public currentFolders: Signal<any[]> = computed(() => {
    return this.currentFileStructureArray().filter( item => item.value !== null);
  })

  public currentFiles: Signal<any[]> = computed(() => {
    return this.currentFileStructureArray().filter( item => item.value === null);
  })

  ngAfterContentInit(): void {
    this.currentFileStructureArray.set(this.viewedFileStructureArray);
  }

  ngOnChanges(_: SimpleChanges): void {
    this.toArray();
    this.currentFileStructureArray.set(this.viewedFileStructureArray);
    this.goToRoot();
  }

  public displayFolderContent(folderIndex: number) {
    this.breadCrumb.push(this.currentFolders()[folderIndex].name ?? '/');
    this.parentFileStructureHistory().push(this.currentFileStructureArray())
    this.currentFileStructureArray.set(this.currentFolders()[folderIndex].value);
  }

  public goToRoot() {
    this.currentFileStructureArray.set(this.viewedFileStructureArray);
    this.parentFileStructureHistory.set([]);
    this.breadCrumb = [];
  }

  public goToAncestor(idx: number) {
    if (idx < this.breadCrumb.length-1) {
      this.currentFileStructureArray.set(this.parentFileStructureHistory()[idx+1]);
      this.parentFileStructureHistory.set(this.parentFileStructureHistory().slice(idx, this.parentFileStructureHistory.length - 1));
      this.breadCrumb = this.breadCrumb.slice(idx, this.breadCrumb.length - 1);
    }
  }

  public goToPrevious() {
    if(this.breadCrumb.length === 1) {
      this.goToRoot();
    } else {
      this.goToAncestor(this.breadCrumb.length - 2);
    }
  }

}
