<div class="modal-header">
    <h5 class="modal-title text-primary" id="exampleModalLabel">{{ "pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.reviews" | translate}}</h5>
</div>
<div class="modal-body">
    <form class="filter" [formGroup]="filterForm">
        <div class="mb-3">
            <label for="userInput" class="form-label">{{ "pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.user" | translate}}</label>
            <input type="text" class="form-control" formControlName="userControl" id="userInput" placeholder="{{ 'pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.placeholderSearchUser' | translate}}">
        </div>
        <div class="slider-container mb-3">
            <label for="ratingSLider" class="form-label">{{ "pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.rating" | translate}}</label>
            <ngx-slider [options]="options" formControlName="sliderControl" id="ratingSlider"></ngx-slider>
        </div>
    </form>
    <button class="reset-button btn btn-primary" (click)="resetFilter()">
        {{ "pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.resetFilter" | translate}}    
    </button>

    <div class="list-users col">
        <div class="header list-group-item">
            <span class="users-label">{{ "pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.usersLabel" | translate}}</span>
            <span class="rating-label">{{"pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.ratingsLabel" | translate}}</span>
        </div>
        <ul class="list-group">
            <div class="scrollable-content">
                @if(!(this.filteredUsers.answers.length > 0)){
                    <li class="user-line list-group-item d-flex justify-content-between align-items-center">
                        <span class="username">{{"pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.noUser" | translate}}</span>
                        <span class="sticker sticker-xs mx-1" style="background-color: #6699ff;">i</span>
                    </li>
                }
                @for (answer of this.filteredUsers.answers; track $index) {
                    <li class="users-line list-group-item d-flex justify-content-between align-items-center" [class.active] = '$index===selectedIndex' (click)="onSelect($index)">
                        <span class="username">{{answer.login}}</span>
                        <span class="sticker sticker-xs mx-1">{{answer.answer}}</span>
                    </li>
                }

            </div>
        </ul>
    </div>
    
    <!-- feedbacks -->
    <div class="feedback col border border-3">
        @if(this.filteredUsers.answers.length > 0 && this.filteredUsers.answers[selectedIndex].complement){
            <div class="user">
                <strong>{{ "pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.from" | translate}}</strong> {{this.filteredUsers.answers[selectedIndex].login}}
            </div>
            <strong>{{ "pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.feedback" | translate}}</strong>
            <div class="text-feedback">{{this.filteredUsers.answers[selectedIndex].complement}}</div>
        }@else {
            <div class="alert alert-info" role="alert">
                <span class="alert-icon"><span class="visually-hidden">Info</span></span>
                <p>{{ "pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.noReview" | translate}}</p> 
            </div>
        }
    </div>
    
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">{{ 'pages.generic.close' | translate }}</button>
</div>
