import { LabelType, NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { debounceTime, Subscription } from 'rxjs';
import { RatingAnswerParameters } from '../../modal';

@Component({
  selector: 'app-rating-answer-component-modal',
  standalone: true,
  imports: [TranslateModule, NgxSliderModule, ReactiveFormsModule],
  templateUrl: './rating-answer-component-modal.component.html',
  styleUrl: './rating-answer-component-modal.component.scss'
})
export class RatingAnswerComponentModalComponent implements OnInit{
  @Input() params : RatingAnswerParameters;

  protected filteredUsers : RatingAnswerParameters;
  protected selectedIndex = 0;

  // Filter
  filterForm: UntypedFormGroup = new UntypedFormGroup({
    userControl: new FormControl(''),
    sliderControl: new UntypedFormControl([0, 10])
  });
  options: Options = {
    floor: 0,
    ceil: 10,
    step: 1,
    noSwitching: true,
    showTicks: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>min :</b>' + value;
        case LabelType.High:
          return '<b>max :</b>' + value;
        default:
          return value.toString();
      }
    }
  };
  private formSubscription: Subscription;


  constructor(
    protected activeModal : NgbActiveModal,
    protected translateService : TranslateService
  ){}

  ngOnInit(): void {
    this.filterUsers();
    this.setupFormChangeListener();
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  // Modal Functions
  onSelect(index){
    this.selectedIndex = index;
  }

  close(){
    this.activeModal.close();
  }

  // Filter Functions
  
  resetFilter(){
    this.filterForm.reset({
      userControl: '',
      sliderControl: [0, 10]
    });
  }

  private setupFormChangeListener(): void {
    this.formSubscription = this.filterForm.valueChanges
    .pipe(debounceTime(300))
    .subscribe(() => {
      this.filterUsers();
      this.selectedIndex = 0;
    });
  }
  
  private filterUsers(){
    const userFilter = this.filterForm.get('userControl').value.toLowerCase();
    const [minRating, maxRating] = this.filterForm.get('sliderControl').value;

    this.filteredUsers = {
      ...this.params,
      answers: this.params.answers.filter(answer => {
        const loginParts = answer.login.toLowerCase().split('@');
        const loginPrefix = loginParts[0];
        return loginPrefix.includes(userFilter) &&
        parseFloat(answer.answer) >= minRating &&
        parseFloat(answer.answer) <= maxRating      
      })
    }
  }
}
