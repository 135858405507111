import { Component, Input, SimpleChanges } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { RatingAnswerParameters } from 'src/app/shared/modals/modal';
import { ModalService } from 'src/app/shared/modals/modal.service';

@Component({
  selector: 'app-rating-answer',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './rating-answer.component.html',
  styleUrl: './rating-answer.component.scss'
})
export class RatingAnswerComponent {

  @Input() data: any[];

  public average: number;
  protected nps : number;
  protected answers : RatingAnswerParameters;
  public maxRange = 10;

  constructor(
    private modalService : ModalService
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      // Generate user regions data
      if(this.data[0]?.average) {
        this.average = Number(this.data[0].average.toFixed(2));
      }
      if(this.data[0]?.nps) {
        this.nps = Number(this.data[0].nps.toFixed(2));
      }
      if(this.data[0]?.ratings){
        this.answers = new RatingAnswerParameters(this.data[0].ratings);
      }
    }
  }

  openModal() {
    this.modalService.openRatingAnswerModal(this.answers);
  }


}
