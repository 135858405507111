<div class="header d-flex mt-3 mb-3">
  <!-- BREADCRUMB -->
  @if(isDrillDown()) {
  <div>
    <!-- root -->
    <span class="breadcrumb-item" (click)="goToRoot()">
      <fa-icon id="root-icon" class="breadcrumb-icon me-1" [icon]="['far', 'folder']" size="lg" data-toggle="tooltip"
        data-placement="top" aria-labelledby="caseIconDesc"></fa-icon>
      <label for="root-icon">{{ 'pages.shared.reports' | translate }}</label>
    </span>
    <!-- children -->
    @for(item of breadCrumb; track item; let idx = $index) {
      <fa-icon id="chevron_right_{{idx}}" class="ms-2 me-2" [icon]="['fas', 'chevron-right']" data-toggle="tooltip"
        data-placement="top" aria-labelledby="caseIconDesc"></fa-icon>
      <span class="breadcrumb-item" (click)="goToAncestor(idx)">
        <fa-icon id="breadcrumb_icon_{{idx}}" class="breadcrumb-icon me-1" [icon]="['far', 'folder']" size="lg"
          data-toggle="tooltip" data-placement="top" aria-labelledby="caseIconDesc"></fa-icon>
        <label for="breadcrumb_icon_{{idx}}">{{item}}</label>
      </span>
    }
  </div>
  }
  
  <!-- back to the previous folder -->
  @if(breadCrumb.length > 0) {
    <div class="d-flex ms-auto">
      <button class="btn btn-primary ms-auto" (click)="goToPrevious()">
        <fa-icon id="chevron_left" class="me-2" [icon]="['fas', 'chevron-left']" data-toggle="tooltip"
        data-placement="top" aria-labelledby="caseIconDesc"></fa-icon>
        <label>{{ 'pages.reports.document.previousFolder' | translate }}</label>
      </button>
    </div>
  }
</div>

<!-- FOLDERS -->
@if(currentFolders()?.length > 0) {
  <h3>{{ 'pages.reports.document.folders' | translate }}</h3>
  <div class="icons-container folders">
    @for(folder of currentFolders(); track folder.name; let idx = $index) {
      <div class="file-struct-directory highlight" (click)="displayFolderContent(idx)">
        <fa-icon id="folder_icon_{{idx}}" class="folder-icon" [icon]="['far', 'folder']" size="3x" data-toggle="tooltip"
        data-placement="top" aria-labelledby="caseIconDesc"></fa-icon>
        <label class="ms-2" for="folder_icon_{{idx}}">{{folder.name}}</label>
      </div>
    }
  </div>
}

<!-- FILES-->
@if(currentFiles()?.length > 0) {
  <h3>{{ 'pages.reports.document.files' | translate }}</h3>
  <div class="icons-container files">
    @for(file of currentFiles(); track file.name; let idx = $index) {
      <div class="file-struct-item">
        <!-- folder icon -->
        <fa-icon class="file-icon" id="file_icon_{{idx}}" [icon]="['far', 'file']" size="3x" data-toggle="tooltip"
        data-placement="top" aria-labelledby="caseIconDesc"></fa-icon>
        <!-- title -->
        <label class="title" for="file_icon_{{idx}}">{{file.name}}</label>
        <!-- details -->
        <label class="details">{{ 'pages.shared.created' | translate }} {{ file.created | date }}</label>
        <!-- download button -->
        <span class="download" title="{{ 'pages.reports.document.downloadFile' | translate }}">
          <fa-icon [icon]="['fas', 'download']" size="lg" class="cursorpointer"
          (click)="clickFile(file.folderPath, file.name, fileAction.download)">
        </fa-icon>
        </span>
        <!-- view button -->
        <span class="view" title="{{ 'pages.reports.document.viewFile' | translate  }}">
          <fa-icon [icon]="['far', 'eye']" size="lg" class="cursorpointer"
          [hidden]="!viewableTypes.includes(getContentType(file.name))"
          (click)="clickFile(file.folderPath, file.name, fileAction.view)">
        </fa-icon>
    </span>
  </div>
}
</div>
}