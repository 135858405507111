export class AdvisoryFilter {
    title: string;
    id: number;
    tdcId: number;
    content: string;
    severity: string;
    tagsName: string;
    threatCategoryTitle: string;
    createdBefore: string;
    createdAfter: string;
    updatedBefore: string;
    updatedAfter: string;
    sortBy: string;
    sortOrder: string;
    limit: number;
    offset: number;

    public mapFilterWithForm(values) {
        this.title = values.title;
        this.id = values.id;
        this.content = values.content;
        this.severity = values.severity && values.severity.length > 0 ? values.severity[0].value : undefined;
        this.tagsName = values.tag;
        this.threatCategoryTitle = values.threatCategoryTitle;
        this.createdBefore = this.convertDateToUTC(values.createdBefore);
        this.createdAfter = this.convertDateToUTC(values.createdAfter);
        this.updatedBefore = this.convertDateToUTC(values.updatedBefore);
        this.updatedAfter = this.convertDateToUTC(values.updatedAfter);
        this.sortBy = values.sortBy;
        this.sortOrder = values.sortOrder;
        this.limit = values.limit;
        this.offset = values.offset;
    }

    /**
     * convert localDate to UTC
     */
    private convertDateToUTC(inputDate) {
        if (inputDate) {
            // add the Zero timsezone for backend request
            return inputDate + ":00Z";
        }
    }
}

export class ReducedAdvisory {
    id: number;
    tdc_id: number;
    title: string;
    severity: number;
    tags: string[];
    threat_category: string;
    timestamp_created: string;
    timestamp_updated: string;
    license_agreement: string;

    // Additionnal params for UI
    severityStyle: string;
    severityLabel: string;
}

export class AdvisoryListResponse {
    count: number;
    items: ReducedAdvisory[];
}

export class TimeStamp {
    year: string;
    monthValue: number;
    dayOfMonth: number;
    hour: number;
    minute: number;
    second: number;
    nano: number;
    dayOfYear: string;
    month: string;
    offset: Offset;
}

export class Offset {
    totalSeconds: number;
    id: string;
}

export class AdvisorySavedFilter {
    name: string;
    _id: string;
    filter: any;
}

export class TagListResponse {
    tags: Tag[];
    countries: Tag[];
    sectors: Tag[];
    regions: Tag[];
}

export class Tag {
    label: string;
    key: string;
}
